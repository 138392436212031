<template>
  <div>
    <el-button type="primary" round @click="isShowAdd">新增部门</el-button>
    <el-table :data="tableData" stripe style="width: 100%" row-key="id" default-expand-all :tree-props="{children: 'children'}">
      <el-table-column prop="id" label="#" width="80">
      </el-table-column>
      <el-table-column prop="name" label="部门" >
      </el-table-column>
      <el-table-column  label="操作" width="300" align="right">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button size="mini" type="" v-if='scope.row.pid == 0' @click="addPost(scope.row)">新增职务</el-button>
          <!-- <el-button size="mini" type="success" v-if='scope.row.pid != 0' @click="Viewpermissions(scope.row)">查看</el-button> -->
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加 -->
    <el-dialog title="新增部门" :visible.sync="dialogVisibleAdd" width="40%">
      <span>新增部门名称</span>
      <el-input v-model="add"></el-input>
      <span>权重(纯数字)</span>
      <el-input v-model="weight" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleAdd = false">取 消</el-button>
        <el-button type="primary" @click="addDetermine">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog title="编辑" :visible.sync="dialogVisibleEdit" width="40%">
      <span>编辑名称</span>
      <el-input v-model="add"></el-input>
      <span>权重(纯数字)</span>
      <el-input v-model="weight" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
      <div v-if="editform === 1">
        <span>权限列表</span>
        <el-checkbox-group v-model="checkList" @change='changeCheck'>
          <el-checkbox :label="item.name" v-for='item in QuanXuanList' :key="item.name" ></el-checkbox>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleEdit = false">取 消</el-button>
        <el-button type="primary" @click="EditDetermine">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 新增职务 -->
    <el-dialog title="新增职务" :visible.sync="addPostisDalg" width="40%">
      <span>职务名称</span>
      <el-input v-model="postName"></el-input>
      <span>权重(纯数字)</span>
      <el-input v-model="weight" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
      <span>权限列表</span>
      <el-checkbox-group v-model="checkList" @change='changeCheck'>
        <el-checkbox :label="item.name" v-for='item in QuanXuanList' :key="item.name"></el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addPostisDalg = false">取 消</el-button>
        <el-button type="primary" @click="addpostyes">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 权限 -->
    <el-dialog title="新增职务" :visible.sync="jurisdictionDalg" width="40%">
      <span slot="footer" class="dialog-footer">
        <el-button @click="jurisdictionDalg = false">取 消</el-button>
        <el-button type="primary" @click="jurisdiction">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      add: "",
      weight: "",
      postName: "",
      dialogVisibleAdd: false,
      dialogVisibleEdit: false,
      addPostisDalg: false,
      jurisdictionDalg: false,
      id: "",
      pid: "",
      QuanXuanList: [],
      checkList: [],
      editform: 0,
      lists: []
    };
  },
  created() {
    this.getRegionList();
    this.getQuanXian();
  },
  methods: {
    openFullScreen2() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
    },
    async getRegionList() {
      this.openFullScreen2();
      const res = await this.axios.get("api/backend/branch");
      console.log(res);
      if (res.status_code === 200) {
        this.loading.close();
        this.tableData = res.data;
      } else if (res.status_code === 401) {
        this.loading.close();
        this.$router.push({ path: "/login" });
      }
    },
    async getQuanXian() {
      const res = await this.axios.get(`api/backend/branch/reviewed-group`);
      if (res.status_code === 200) {
        this.QuanXuanList = res.data;
        console.log(this.QuanXuanList);
      }
    },
    isShowAdd() {
      this.dialogVisibleAdd = true;
      this.add = "";
      this.weight = "";
    },
    async addDetermine() {
      const res = await this.axios.post("api/backend/branch", {
        name: this.add,
        weight: this.weight
      });
      if (res.status_code === 200) {
        this.dialogVisibleAdd = false;
        this.$message.success("添加成功");
        this.getRegionList();
        this.add = "";
        this.weight = "";
      } else {
        // this.$message.error(res.error);
      }
    },
    handleEdit(row) {
      console.log(row);
      this.checkList = [];
      if (row.pid !== 0) {
        // 判断是否显示权限列表
        this.editform = 1;
      } else {
        this.editform = 0;
      }
      this.dialogVisibleEdit = true;
      this.id = row.id;
      this.add = row.name;
      this.weight = row.weight;

      if (row.reviewed_json) {
        // this.checkList = row.reviewed_json;
        let list = [];
        console.log(this.QuanXuanList);
        console.log(row.reviewed_json);
        this.QuanXuanList.forEach((item, index) => {
          console.log(item);
          row.reviewed_json.forEach(val => {
            if (val == item.value) {
              list.push(item);
            }
          });
        });
        console.log(list);
        list.forEach(item => {
          this.checkList.push(item.name);
        });
        console.log(this.checkList);
      } else {
        this.checkList = [];
      }
    },
    async EditDetermine() {
      const res = await this.axios.put(`api/backend/branch/${this.id}`, {
        name: this.add,
        weight: this.weight,
        reviewed_json: this.lists
      });
      console.log(res);
      if (res.status_code === 200) {
        this.getRegionList();
        this.$message.success("修改成功");
        this.add = "";
        this.weight = "";
        this.dialogVisibleEdit = false;
      } else {
        this.$message.error(res.error);
      }
    },
    // 新增职务
    addPost(row) {
      console.log(row);
      this.pid = row.id;
      this.weight = "";
      this.postName = "";
      this.checkList = [];
      this.addPostisDalg = true;
    },
    async addpostyes() {
      const res = await this.axios.post(`api/backend/branch`, {
        name: this.postName,
        weight: this.weight,
        reviewed_json: this.lists,
        pid: this.pid
      });
      console.log(res);
      if (res.status_code === 200) {
        this.getRegionList();
        this.$message.success("添加成功");
        this.addPostisDalg = false;
      }
    },
    changeCheck(val) {
      let list = [];
      this.lists = [];
      console.log(val);
      val.forEach(item => {
        console.log(item);
        list.push(this.QuanXuanList.filter(lev => lev.name == item)[0]);
      });
      console.log(this.lists);
      list.forEach(items => this.lists.push(items.value));
    },
    async Viewpermissions(row) {
      const res = await this.axios.get(`api/backend/branch/reviewed-group`);
      console.log(res);
    },
    jurisdiction() {},
    handleDelete(row) {}
  }
};
</script>


<style lang="less" scoped>
/deep/.el-table .cell {
  padding-left: 0px;
}
</style>




